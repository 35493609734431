<template>
  <div id="dialogSettingUser">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span
          ><v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="isEdit">
                <v-text-field
                  v-model="item.UserId"
                  label="รหัส"
                  dense
                  required
                  readonly
                  ref="UserId"
                  @keydown.enter="NextFocus('FirstName')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.FirstName"
                  label="ชื่อ"
                  dense
                  required
                  ref="FirstName"
                  @keydown.enter="NextFocus('LastName')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.LastName"
                  label="นามสกุล"
                  dense
                  required
                  ref="LastName"
                  @keydown.enter="NextFocus('Phone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.Phone"
                  label="เบอร์โทร"
                  dense
                  required
                  ref="Phone"
                  @keydown.enter="NextFocus('Position')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.Position"
                  label="ตำแหน่ง"
                  dense
                  required
                  ref="Position"
                  @keydown.enter="NextFocus('Status')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="isEdit">
                <v-autocomplete
                  v-model="item.Status"
                  :items="enumStatus"
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  dense
                  label="สถานะ"
                  ref="Status"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel">
            ยกเลิก
          </v-btn>
          <v-btn class="primary" @click="submit" ref="submit">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";
export default {
  name: "DialogSettingUser",
  props: ["modal", "item", "itemIndex"],
  data() {
    return {
      enumStatus: baseEnum.status.data,
      dialog: false,
      itemEdit: {},
      indexEdit: -1,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    dialogTitle() {
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    },
  },
  methods: {
    ...mapActions("settings", ["actUserUpdate", "actUserCreate"]),
    closeModal() {
      this.$emit("close", false);
    },
    cancel() {
      this.closeModal();
    },
    submit() {
      if (this.indexEdit == -1) {
        //เพิ่ม
        this.itemEdit.CreatedBy = this.user.UserId;
        this.itemEdit.ModifiedBy = this.user.UserId;
        this.actUserCreate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.success) {
            this.closeModal();
          } else {
            this.Error(data.message);
          }
        });
      } else {
        //แก้ไข
        this.itemEdit.ModifiedBy = this.user.UserId;
        this.actUserUpdate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.success) {
            this.closeModal();
          } else {
            this.Error(data.message);
          }
        });
      }
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.itemEdit = this.item;
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
  },
};
</script>

<style></style>
